import { ExecEdCertCategoryConfigs } from 'ExecEdEligibility/helpers/ExecEdCategories';
import { type ExecEdCertCategory } from 'ExecEdEligibility/ExecEdEligibility.types';
import { getProgramConfigValue, type ProgramType } from 'Program';
import { useTranslation } from 'react-i18next';
import { CertActionButton } from './CertActionButton';

type Props = {
    recommendedProgramType: ProgramType;
    category: ExecEdCertCategory;
    onReviewRegisterClick: (programType: ProgramType) => void;
    onLearnMoreClick: (programType: ProgramType) => void;
};

export function CertRecommendedProgram({
    recommendedProgramType,
    category,
    onReviewRegisterClick,
    onLearnMoreClick,
}: Props) {
    const { t } = useTranslation('back_royal');
    const categoryConfig = ExecEdCertCategoryConfigs[category];

    const programTitle = getProgramConfigValue(recommendedProgramType, 'standaloneShortProgramTitle');

    return (
        <div
            data-testid="recommended-program"
            className="flex w-[230px] flex-col items-center justify-start gap-[10px] p-[5px]"
        >
            <div className="mb-[10px] text-xs leading-4 opacity-60">
                {t(categoryConfig.modalHeadingLocale.key, categoryConfig.modalHeadingLocale.values ?? {})}
            </div>
            <div className="flex justify-center text-left text-md leading-[21px] xs:justify-start">
                {programTitle as string}
            </div>
            <CertActionButton onClick={() => onReviewRegisterClick(recommendedProgramType)}>
                {t('exec_ed_eligibility.cert_acceptance_modal.review_and_register')}
            </CertActionButton>
            <button
                type="button"
                className="border-none bg-transparent p-0 text-xxs leading-[14px] underline"
                onClick={() => onLearnMoreClick(recommendedProgramType)}
            >
                {t('exec_ed_eligibility.cert_acceptance_modal.learn_more')}
            </button>
        </div>
    );
}
