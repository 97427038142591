import clsx from 'clsx';
import { linearLightBlueGradientStyle } from 'ExecEdEligibility/helpers/gradients';
import { type ReactNode, type ButtonHTMLAttributes } from 'react';

type Props = {
    children?: ReactNode;
    className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function CertActionButton({ children, className, ...buttonProps }: Props) {
    return (
        <button
            type="button"
            className={clsx('flex items-center justify-center border border-white px-3 py-[11px]', className)}
            style={{ ...linearLightBlueGradientStyle('to right') }}
            {...buttonProps}
        >
            {children}
        </button>
    );
}
