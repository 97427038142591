import clsx from 'clsx';

type Props = {
    viewBox?: string;
    className?: string;
};

export function QuanticShieldGold({ viewBox, className }: Props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className={clsx('block', className)}
            viewBox={viewBox}
        >
            <defs>
                <filter x="-38.1%" y="-32.7%" width="176.2%" height="165.3%" filterUnits="objectBoundingBox" id="g">
                    <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.504971591 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g>
                <path fill="#FFBE00" d="M114.813 207.365v42.728l36.875 21.364 36.874-21.364v-42.728L151.688 186z" />
                <g filter="url(#g)" transform="translate(130.865 204.601)" fill="#FFF">
                    <path d="M20.825 24.12v12.07l20.82 12.062v-12.07z" />
                    <path d="M20.823 0L0 12.063v24.129l20.822 12.063V36.186l-10.406-6.03V18.1l10.407-6.03 10.406 6.03v12.055l10.416-6.034V12.063z" />
                </g>
            </g>
            <g fill="#F5A623">
                <path d="M139.732 280.337c-3.44 0-6.204 1-8.74 3.286 2.491 1.576 5.01 2.279 7.954 2.279 3.613 0 6.824-1.028 9.958-3.207-4.169-1.949-6.528-2.358-9.172-2.358m7.235.338c-1.027-3.035-2.395-5.333-4.183-7.106-1.537-1.53-4.86-4.027-9.953-3.875.511 2.813 1.578 4.86 3.43 6.696 2.806 2.784 7.208 3.872 10.706 4.285m-18.454-3.857c-3.297 0-6.126.75-9.098 2.49 2.81 1.85 5.558 2.649 8.838 2.649 3.934 0 6.646-1.166 9.079-2.825-2.722-1.49-5.427-2.314-8.82-2.314" />
                <path d="M132.241 277.105c.062-5.733-1.276-8.501-3.376-10.7-1.384-1.456-3.093-2.7-6.787-4.14-.408 4.023 1.024 7.964 4.139 11.054 1.774 1.758 3.474 2.858 6.024 3.786m-16.362-6.609c-2.934 0-5.054.67-7.238 1.992 2.086 2.51 4.943 4.473 9.375 4.473 2.864 0 5.67-.668 8.376-1.989-2.574-2.208-6.01-4.476-10.513-4.476m6.179 1.107c-.16-4.644-1.996-9.25-4.998-12.158-1.565-1.512-3.522-3.07-8.128-4.486-.2 2.466.196 6.767 4.23 10.77 2.69 2.666 5.429 4.313 8.896 5.874m-7.035-2.081c-1.274-2.492-3.24-5.274-6.225-7.134-4.17-2.603-7.9-2.886-11.17-2.396 1.428 3.043 2.926 5.204 5.357 7.102 3.28 2.56 7.705 2.835 12.038 2.428m-8.583-9.742c-.184-3.164-.945-6.98-2.97-9.684-2.018-2.692-4.532-4.358-8.47-5.445-.022 4.123 1.35 7.577 4.092 10.3 1.809 1.8 4.314 3.438 7.347 4.828m-1.815-18.289c0-3.87-1.733-7.211-5.055-9.82a11.749 11.749 0 00-1.445 5.687c0 4.355 2.39 8.12 5.007 11.159 1.164-2.841 1.493-4.587 1.493-7.026m49.35 41.205c3.133 2.178 6.349 3.207 9.954 3.207 2.942 0 5.468-.703 7.959-2.28-2.538-2.286-5.306-3.285-8.746-3.285-2.641 0-4.997.41-9.167 2.358m16.073-13.001a13.423 13.423 0 00-9.958 3.875c-1.787 1.772-3.148 4.07-4.18 7.106 3.501-.412 7.901-1.501 10.708-4.285 1.848-1.836 2.915-3.882 3.43-6.696m-4.497 9.438c2.425 1.66 5.14 2.825 9.07 2.825 3.285 0 6.028-.799 8.84-2.648-2.973-1.74-5.796-2.49-9.094-2.49-3.399 0-6.102.822-8.816 2.313" />
                <path d="M180.793 262.265c-3.69 1.44-5.393 2.684-6.784 4.139-2.094 2.2-3.436 4.968-3.376 10.701 2.554-.928 4.254-2.028 6.03-3.786 3.111-3.09 4.545-7.031 4.13-11.054m-4.307 12.707c2.701 1.32 5.512 1.988 8.378 1.988 4.432 0 7.289-1.962 9.372-4.472-2.18-1.321-4.301-1.992-7.235-1.992-4.504 0-7.946 2.268-10.515 4.476m13.23-9.243c4.03-4.003 4.43-8.304 4.229-10.77-4.605 1.416-6.564 2.974-8.127 4.486-3 2.907-4.841 7.515-4.999 12.159 3.468-1.563 6.207-3.21 8.897-5.875m4.364-3.341c-2.983 1.86-4.956 4.642-6.221 7.134 4.325.407 8.753.132 12.033-2.429 2.431-1.897 3.926-4.057 5.357-7.1-3.27-.49-6.999-.208-11.17 2.395m13.801-17.737c-3.938 1.087-6.453 2.753-8.471 5.444-2.029 2.706-2.793 6.52-2.974 9.684 3.033-1.39 5.54-3.029 7.347-4.828 2.745-2.723 4.115-6.177 4.097-10.3m-3.125-7.294c0-2.01-.482-3.89-1.448-5.687-3.318 2.609-5.052 5.95-5.052 9.82 0 2.44.327 4.184 1.491 7.026 2.614-3.04 5.009-6.804 5.009-11.159m-51.111 49.617c-.336-1.116-.493-2.152-.618-2.986-.12-.774-.202-1.33-.381-1.698-.241-.494-.59-.693-1.202-.693-.617 0-.966.2-1.207.693-.18.368-.269.924-.384 1.698-.123.834-.284 1.87-.62 2.986-.413 1.353-1.028 2.54-1.522 3.494-.5.953-.858 1.65-.858 2.18 0 .726.496 1.23 1.213 1.23 1.162 0 1.572-1.235 2.088-2.797l.22-.641.174-.54c.271-.83.5-1.55.896-1.55.386 0 .615.721.888 1.55l.18.54.213.641c.514 1.562.925 2.796 2.096 2.796.708 0 1.206-.503 1.206-1.23 0-.529-.357-1.226-.855-2.18-.497-.953-1.11-2.14-1.527-3.493" />
            </g>
        </svg>
    );
}
