import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import parseHtml from 'html-react-parser';
import { ExecEdCertCategoryConfigs } from '../helpers/ExecEdCategories';
import { type ExecEdEligibilityBundle, type ExecEdCertCategory } from '../ExecEdEligibility.types';

type Props = {
    filteredEligibilityBundle: ExecEdEligibilityBundle;
    showCertCongratsModal: () => void;
};

export function ExecEdEligibilityBundleBox({ filteredEligibilityBundle, showCertCongratsModal }: Props) {
    const { t } = useTranslation('back_royal');
    const categoriesEligibleFor = useMemo(() => {
        const categories: ExecEdCertCategory[] = [];
        const eligibleProgramTypes = filteredEligibilityBundle?.eligiblePrograms.map(p => p.programType);

        Object.entries(ExecEdCertCategoryConfigs).forEach(([category, config]) => {
            if (config.programTypes.some(p => eligibleProgramTypes?.includes(p))) {
                categories.push(category as ExecEdCertCategory);
            }
        });

        return categories;
    }, [filteredEligibilityBundle]);

    const openCongratsModal = useCallback(() => {
        showCertCongratsModal();
    }, [showCertCongratsModal]);

    return (
        <div className="flex flex-col rounded bg-gradient-to-br from-[#2266D0] to-[#0C2C63] p-[15px] text-white">
            <div className="flex justify-between">
                <h2 className="m-0 text-lg font-semibold text-white">
                    {parseHtml(t('exec_ed_eligibility.bundle_box.exec_ed_programs'))}
                </h2>
                <div className="relative -rotate-[15deg] text-yellow">
                    <i className="fa fa-sharp fa-seal rotate-[23deg] text-[40px]" />
                    <i className="fa fa-sharp fa-seal absolute right-0 text-[40px]" />
                    <p className="align-center absolute top-0 h-full w-full text-center text-[11px] font-semibold leading-[40px] text-white text-shadow">
                        {t('exec_ed_eligibility.bundle_box.new')}
                    </p>
                </div>
            </div>
            <p className="mb-0 pb-[15px] pt-[15px] text-[15px] leading-[21px]">
                {t('exec_ed_eligibility.bundle_box.eligible_to_enroll')}
            </p>
            <ul className="mb-[20px] list-none p-0">
                {categoriesEligibleFor.map(category => (
                    <li key={category} className="mb-[2px] flex items-center font-semibold text-white">
                        <i className="fa-light fa-hexagon-check -mt-[1px] me-[7.5px] text-[13px]" />
                        {t(`exec_ed_eligibility.bundle_box.${category}`)}
                    </li>
                ))}
            </ul>
            <button
                type="button"
                className="mb-[5px] mt-0 h-auto self-center rounded-[50px] border-[1px] bg-transparent px-[22px] py-[10px] text-[15px] font-semibold normal-case leading-[15px] text-white transition-colors hover:bg-white hover:text-[#194695]"
                onClick={() => openCongratsModal()}
            >
                {t('exec_ed_eligibility.bundle_box.review_and_register')}
            </button>
        </div>
    );
}
