import { useTranslation } from 'react-i18next';
import { QuanticShieldGold } from './QuanticShieldGold';

type Props = {
    name: string;
};

export function CertModalHeader({ name }: Props) {
    const { t } = useTranslation('back_royal');
    return (
        <>
            <h3 className="mb-4 flex flex-col items-center text-center leading-4 ">
                <span className="text-xs font-normal uppercase opacity-60">
                    {t('exec_ed_eligibility.cert_acceptance_modal.congratulations')}
                </span>
                <span className="text-xs font-medium uppercase">{name}</span>
            </h3>
            <div className="logo mb-5 h-[55px] w-[58px]">
                <QuanticShieldGold viewBox="95 185 115 110" className="h-full w-full" />
            </div>
            <p className=" mx-auto my-0 text-center leading-5 opacity-60 max-xs:max-w-[300px] xs:w-auto md:whitespace-nowrap">
                {t('exec_ed_eligibility.cert_acceptance_modal.you_are_eligible_to_enroll')}
            </p>
        </>
    );
}
